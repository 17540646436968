//Fonts
$archivo: 'Archivo', sans-serif;
$playfair: 'Playfair Display SC', serif;
$qwitcher: 'Qwitcher Grypen', cursive;

//Colors
$white: #ffffff;
$black: #000000;
$salmon: #e6bfbe;
$text: #9a7c36;
$hover: #a78943;

// Pixels X REM
$browser-context: 16;
@function rem($pixels, $context: $browser-context) {
  @return #{calc($pixels / $context)}rem;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-5px);
  }
}
